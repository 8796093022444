@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Nanum+Pen+Script&display=swap');

body {
    /*position: fixed;*/
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 20px;
    font-family: Nanum Pen Script, cursive;
}

.bingo-card {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 5px;
    width: 95vw;
    margin: 0 auto 20px;
}

.tile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 4px;
    font-size: 4vw;
    padding: 3px;
    font-family: Arial, Helvetica, sans-serif;
}

.tile.clicked {
    background-color: #EED8A8;
    /*color: white;*/
}

.tile.xx {
    background-color: #BDC6AF;
    font-family: Gloria Hallelujah, cursive;
    color: white;

    word-break: break-all;
    overflow-wrap: break-word;
}

.tile.xy {
    font-family: Gloria Hallelujah, cursive;
    background-color: #C9B4A6;
    color: white;

    word-break: break-all;
    overflow-wrap: break-word;
}

.tile.clickable {
    cursor: pointer;
    font-size: 3.5vw;
    font-size: min(3.5vw, 20px);
    border: 1px solid #EED8A8;

}

.tile span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
